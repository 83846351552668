import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="About" isOpen={isOpen} handleClose={handleClose}>
    <p className="text-sm text-gray-500">
                      This is an open source clone of the game Wordle adapted to SENĆOŦEN by <a href="https://aidanpine.ca" className="underline font-bold">Aidan Pine</a> and PEPAḴIYE Ashley Cooper -{' '}

                      check out   <a
                        href="https://github.com/hannahcode/wordle"
                        className="underline font-bold"
                      >the original code</a> by <a href="https://www.hannahmariepark.com/" className="underline font-bold">Hannah Park</a>
                      {' '}or have a look at{' '}
                      <a
                        href="https://github.com/roedoejet/AnyLanguage-Wordle"
                        className="underline font-bold"
                      >
                        my fork
                      </a>{' '}and customize it for another language! The words in this game are sourced from the SENĆOŦEN dictionary published by Timothy Montler. If you're stuck and want to look up some SENĆOŦEN words, try <a href="https://itservices.cas.unt.edu/~montler/saanich/wordlist/" className="underline font-bold">the SENĆOŦEN wordlist</a>{'. '}Or,
                      {' you can also '}
                      <a
                        href="https://www.powerlanguage.co.uk/wordle/"
                        className="underline font-bold"
                      >
                        play the original here
                      </a>
                    </p>
    </BaseModal>
  )
}
